<template>
    <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Статистика APIv2 по пользователям</div>
        <div class="tools">
        </div>
      </div>
      <div class="card-body">
        <users-white-list-table/>
      </div>
    </div>
  </div>
</template>

<script>
import UsersWhiteListTable from '../../components/Tables/UsersWhiteListTable.vue';
export default {
  components: { UsersWhiteListTable },
  name: "whiteListTable"
};
</script>

<style>
</style>