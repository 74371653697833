<template>
  <div v-if="params.test === 'test'">
    <div>"ТЭИ – версия для топ-руководителей"</div>
    <div>"ТЭИ – версия для линейных руководителей"</div>
  </div>
  <div v-else-if="params.active === 'active'">
    <div>{{ params.data.full.full_active_count }}</div>
    <div>{{ params.data.standard.standard_active_count }}</div>
  </div>
  <div v-else-if="params.free === 'free'">
    <div>{{ params.data.full.full_free_count }}</div>
    <div>{{ params.data.standard.standard_free_count }}</div>
  </div>
  <div v-else-if="params.issued === 'issued'">
    <div>{{ params.data.full.full_issued_count }}</div>
    <div>{{ params.data.standard.standard_issued_count }}</div>
  </div>
  <div v-else-if="params.total === 'total'">
    <div>{{ params.data.full.full_total  }}</div>
    <div>{{ params.data.standard.standard_total }}</div>
  </div>
</template>
<script setup>
</script>

